import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import PublicAnnouncementDetailsView from './publicAnnouncementDetailsView';
import PreloaderComponent from '../../commons/preloader/preloaderComponent';
import FooterComponent from '../../commons/footer/footerComponent';
import HeaderComponent from '../../commons/header/headerComponent';
import { getCookie } from '../../../utils/cookies';
import api from '../../../utils/api';

class PublicAnnouncementDetailsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            language: 'FI',
            announcement: null,
            isLoading: true,
            success: true,
            message: null,
        };
    }

    render() {
        const {
            isLoading,
            announcement,
            message,
            success,
            language,
        } = this.state;

        if (isLoading) {
            return <PreloaderComponent />;
        }

        return (
            <div>
                <HeaderComponent />
                <PublicAnnouncementDetailsView
                    announcement={announcement}
                    message={message}
                    success={success}
                    isLoading={isLoading}
                    setLanguage={this.setLanguage}
                    language={language}
                />
                <FooterComponent />
            </div>
        );
    }

    setLanguage = (language) => {
        this.setState({
            ...this.state,
            ...{
                language: language,
            },
        });
    };

    async componentDidMount() {
        try {
            this.setState({ isLoading: true });
            let response = await api.get('/announcement/', {
                headers: {
                    Authorization: getCookie('jasenrekisteri-token'),
                    'Content-Type': 'application/json',
                },
                params: {
                    id: this.state.id,
                },
            });

            this.setState({
                ...this.state,
                ...{
                    isLoading: false,
                    success: true,
                    announcement: response.data,
                },
            });
        } catch (e) {
            this.setState({
                ...this.state,
                ...{
                    success: false,
                    message:
                        e?.response?.data?.message ??
                        'Pyyntö tietojen hakemiselle epäonnistui.',
                    isLoading: false,
                },
            });
        }
    }
}

export default withRouter(PublicAnnouncementDetailsComponent);
