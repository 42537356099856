import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import AnnouncementDetailsView from './announcementDetailsView';
import PreloaderComponent from '../../commons/preloader/preloaderComponent';
import FooterComponent from '../../commons/footer/footerComponent';
import HeaderComponent from '../../commons/header/headerComponent';
import { getCookie } from '../../../utils/cookies';
import api from '../../../utils/api';


class AnnouncementDetailsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            publishType: 'now', // 'now' or 'scheduled
            platforms: [],
            types: [],
            focusedEditor: null,
            announcement: null,
            isLoading: true,
            success: true,
            message: null,
        };
    }

    render() {
        const {
            isLoading,
            announcement,
            message,
            success,
            publishType,
            platforms,
            types,
        } = this.state;

        if (isLoading) {
            return <PreloaderComponent />;
        }

        const quillModules = {
            toolbar: {
                container: [
                    [{ header: [1, 2, false] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ color: [] }, { background: [] }],
                    ['blockquote', 'code-block'],
                    [
                        { list: 'ordered' },
                        { list: 'bullet' },
                        { indent: '-1' },
                        { indent: '+1' },
                    ],
                    [{ align: [] }],
                    ['link', 'image'],
                ],
            },
        };

        return (
            <div>
                <HeaderComponent />
                <AnnouncementDetailsView
                    announcement={announcement}
                    handleInputChange={this.handleInputChange}
                    handleFocusChange={this.handleFocusChange}
                    changeContentFI={this.changeContentFI}
                    changeContentEN={this.changeContentEN}
                    quillModules={quillModules}
                    save={this.save}
                    message={message}
                    success={success}
                    publish={this.publish}
                    deleteAnnouncement={this.deleteAnnouncement}
                    isLoading={isLoading}
                    archive={this.archive}
                    makeTemplate={this.makeTemplate}
                    duplicate={this.duplicate}
                    publishType={publishType}
                    setPublishType={this.setPublishType}
                    changePublishDate={this.changePublishDate}
                    handlePlatformChange={this.handlePlatformChange}
                    platforms={platforms}
                    types={types}
                    uploadAttachment={this.uploadAttachment}
                    removeAttachment={this.removeAttachment}
                    canSendAnnouncement={this.canSendAnnouncement}
                />
                <FooterComponent />
            </div>
        );
    }

    canSendAnnouncement = () => {
        const announcementStatus = this.state.announcement.status;
        return announcementStatus !== 'published' && announcementStatus !== 'archived' &&
            announcementStatus !== 'template' && this.state.announcement.platforms.length > 0 &&
            this.state.announcement.titleFI.length > 0 && this.state.announcement.titleEN.length
    }


    setPublishType = (type) => {
        this.setState({
            ...this.state,
            ...{
                publishType: type,
                announcement: {
                    ...this.state.announcement,
                    status:
                        this.state.announcement.status === 'scheduled' &&
                        type === 'now'
                            ? 'draft'
                            : this.state.announcement.status,
                    publishedAt:
                        type === 'now'
                            ? null
                            : this.state.announcement.publishedAt ?? new Date(),
                },
            },
        });
    };

    changePublishDate = (date) => {
        this.setState({
            ...{
                announcement: {
                    ...this.state.announcement,
                    publishedAt: date,
                },
            },
        });
    };

    handlePlatformChange = (event) => {
        const target = event.target;
        const value = target.checked;
        const platform = target.name;

        let platforms = this.state.announcement.platforms;

        if (value) {
            if (!platforms.includes(platform)) {
                platforms = [...platforms, platform];
            }
        } else {
            platforms = platforms.filter((p) => p !== platform);
        }

        this.setState({
            announcement: {
                ...this.state.announcement,
                platforms: platforms,
            },
        });
    };

    handleInputChange = (event) => {
        const target = event.target;
        const value =
            target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            announcement: {
                ...this.state.announcement,
                [name]: value,
            },
        });
    };

    handleFocusChange = (selection, source, editor, quillRef) => {
        this.setState({
            ...this.state,
            ...{
                focusedEditor: selection
            },
        });
    };


    changeContentFI = (content, delta, source, editor) => {
        this.setState({
            ...this.state,
            ...{
                announcement: {
                    ...this.state.announcement,
                    ...{
                        contentFI: editor.getContents(),
                    },
                },
            },
        });
    };

    changeContentEN = (content, delta, source, editor) => {
        this.setState({
            ...this.state,
            ...{
                announcement: {
                    ...this.state.announcement,
                    ...{
                        contentEN: editor.getContents(),
                    },
                },
            },
        });
    };



    save = async () => {
        try {
            this.setState({ isLoading: true });
            let response = await api.post(
                '/announcement/save',
                this.state.announcement,
                {
                    headers: {
                        Authorization: getCookie('jasenrekisteri-token'),
                        'Content-Type': 'application/json',
                    },
                }
            );

            this.setState({
                ...this.state,
                ...{
                    isLoading: false,
                    success: true,
                    announcement: response.data,
                },
            });
        } catch (e) {
            this.setState({
                ...this.state,
                ...{
                    success: false,
                    message:
                        e?.response?.data?.message ??
                        'Tallentaminen epäonnistui',
                    isLoading: false,
                },
            });
        }
    };

    publish = async () => {
        try {
            this.setState({ isLoading: true });
            await this.save();
            if (this.state.publishType === 'scheduled') {
                this.setState({
                    ...this.state,
                    ...{
                        isLoading: false,
                        success: true,
                        announcement: {
                            ...this.state.announcement,
                            status: 'scheduled',
                        },
                    },
                });
                alert('Tiedote ajastettu onnistuneesti!');
                this.props.history.push('/admin/announcements');
                return;
            }
            await api.post(
                '/announcement/publish',
                { id: this.state.announcement.id },
                {
                    headers: {
                        Authorization: getCookie('jasenrekisteri-token'),
                        'Content-Type': 'application/json',
                    },
                }
            );

            this.setState({
                ...this.state,
                ...{
                    isLoading: false,
                    success: true,
                },
            });

            alert('Tiedote julkaistu onnistuneesti!');
            this.props.history.push('/admin/announcements');
        } catch (e) {
            this.setState({
                ...this.state,
                ...{
                    success: false,
                    message:
                        e?.response?.data?.message ??
                        'Julkaiseminen epäonnistui',
                    isLoading: false,
                },
            });
        }
    };

    archive = async () => {
        try {
            this.setState({ isLoading: true });
            await this.save();
            await api.post(
                '/announcement/archive',
                { id: this.state.announcement.id },
                {
                    headers: {
                        Authorization: getCookie('jasenrekisteri-token'),
                        'Content-Type': 'application/json',
                    },
                }
            );

            this.setState({
                ...this.state,
                ...{
                    isLoading: false,
                    success: true,
                    announcement: {
                        ...this.state.announcement,
                        status: 'archived',
                    },
                },
            });
            alert('Tiedote arkistoitu onnistuneesti!');
        } catch (e) {
            this.setState({
                ...this.state,
                ...{
                    success: false,
                    message:
                        e?.response?.data?.message ??
                        'Arkistoiminen epäonnistui',
                    isLoading: false,
                },
            });
        }
    };

    duplicate = async () => {
        try {
            this.setState({ isLoading: true });
            await this.save();

            const response = await api.post(
                '/announcement/duplicate',
                { id: this.state.announcement.id },
                {
                    headers: {
                        Authorization: getCookie('jasenrekisteri-token'),
                        'Content-Type': 'application/json',
                    },
                }
            );

            this.setState({
                ...this.state,
                ...{
                    isLoading: false,
                    success: true,
                },
            });
            await this.props.history.replace('/admin/announcements/');
            await this.props.history.push(
                '/admin/announcement/' + response.data.id
            );
        } catch (e) {
            this.setState({
                ...this.state,
                ...{
                    success: false,
                    message:
                        e?.response?.data?.message ??
                        'Arkistoiminen epäonnistui',
                    isLoading: false,
                },
            });
        }
    };

    makeTemplate = async () => {
        try {
            this.setState({ isLoading: true });
            await this.save();

            await api.post(
                '/announcement/make-template',
                { id: this.state.announcement.id },
                {
                    headers: {
                        Authorization: getCookie('jasenrekisteri-token'),
                        'Content-Type': 'application/json',
                    },
                }
            );

            this.setState({
                ...this.state,
                ...{
                    isLoading: false,
                    success: true,
                    announcement: {
                        ...this.state.announcement,
                        status: 'template',
                    },
                },
            });

            alert('Tiedotepohja luotu onnistuneesti!');
        } catch (e) {
            this.setState({
                ...this.state,
                ...{
                    success: false,
                    message:
                        e?.response?.data?.message ??
                        'Pohjaksi muuttaminen epäonnistui',
                    isLoading: false,
                },
            });
        }
    };

    removeAttachment = async (attachment) => {
        try {
            await this.save();

            this.setState({ isLoading: true });

            const response = await api.delete('/announcement/attachment', {
                headers: {
                    Authorization: getCookie('jasenrekisteri-token'),
                    'Content-Type': 'multipart/form-data',
                },
                params: {
                    id: this.state.announcement.id,
                    attachment: attachment,
                },
            });

            this.setState({
                ...this.state,
                ...{
                    isLoading: false,
                    success: true,
                    announcement: response.data,
                },
            });
        } catch (e) {
            this.setState({
                ...this.state,
                ...{
                    success: false,
                    message:
                        e?.response?.data?.message ??
                        'Liitteen poistaminen epäonnistui',
                    isLoading: false,
                },
            });
        }
    };

    uploadAttachment = async (e) => {
        e.preventDefault();

        try {
            const formData = new FormData();
            formData.append('attachment', e.target.files[0]);

            await this.save();

            this.setState({ isLoading: true });

            const response = await api.post(
                '/announcement/attachment',
                formData,
                {
                    headers: {
                        Authorization: getCookie('jasenrekisteri-token'),
                        'Content-Type': 'multipart/form-data',
                    },
                    params: { id: this.state.announcement.id },
                }
            );

            this.setState({
                ...this.state,
                ...{
                    isLoading: false,
                    success: true,
                    announcement: response.data,
                },
            });
        } catch (e) {
            this.setState({
                ...this.state,
                ...{
                    success: false,
                    message:
                        e?.response?.data?.message ??
                        'Liitteen lisääminen epäonnistui',
                    isLoading: false,
                },
            });
        }
    };

    deleteAnnouncement = async () => {
        try {
            this.setState({ isLoading: true });
            await api.delete(
                '/announcement/',

                {
                    headers: {
                        Authorization: getCookie('jasenrekisteri-token'),
                        'Content-Type': 'application/json',
                    },
                    params: { id: this.state.announcement.id },
                }
            );

            this.setState({
                ...this.state,
                ...{
                    isLoading: false,
                    success: true,
                },
            });

            this.props.history.push('/admin/announcements');
        } catch (e) {
            this.setState({
                ...this.state,
                ...{
                    success: false,
                    message:
                        e?.response?.data?.message ?? 'Poistaminen epäonnistui',
                    isLoading: false,
                },
            });
        }
    };


    async componentDidMount() {
        try {
            this.setState({ isLoading: true });
            let response = await api.get('/announcement/', {
                headers: {
                    Authorization: getCookie('jasenrekisteri-token'),
                    'Content-Type': 'application/json',
                },
                params: {
                    id: this.state.id,
                },
            });

            let platformsResponse = await api.get('/announcement/platforms', {
                headers: {
                    Authorization: getCookie('jasenrekisteri-token'),
                    'Content-Type': 'application/json',
                },
            });
            let typesResponse = await api.get('/announcement/types', {
                headers: {
                    Authorization: getCookie('jasenrekisteri-token'),
                    'Content-Type': 'application/json',
                },
            });
            
            this.setState({
                ...this.state,
                ...{
                    isLoading: false,
                    success: true,
                    announcement: response.data,
                    platforms: platformsResponse.data,
                    types: typesResponse.data,
                    publishType:
                        (response.data.publishedAt &&
                            response.data.status === 'draft') ||
                        response.data.status === 'scheduled'
                            ? 'scheduled'
                            : 'now',
                },
            });
        } catch (e) {
            this.setState({
                ...this.state,
                ...{
                    success: false,
                    message:
                        e?.response?.data?.message ??
                        'Pyyntö tietojen hakemiselle epäonnistui.',
                    isLoading: false,
                },
            });
        }
    }
}

export default withRouter(AnnouncementDetailsComponent);
